<template>
    <div class="custListView">
        <div v-if="listData.length == 0">
            <!--暂无算价单-->
            <nodata title="暂无算价单" img="noCustomer"></nodata>
            <div style="text-align:center">
                <!-- <van-button type="danger" size="small">马上创建</van-button> -->
            </div>
        </div>
        <template v-else>
            <!--共-->
            <!--个算价单-->
            <div class="countBox" v-if="!countNumNo">{{$t('mx_mobile.Client.1585117918604')}}<span class="count">{{countNum}}</span>个算价单</div>
            <div class="list clientList" v-for="(item, index) in listData" :key="index" :title="item.custName + ''" @touchstart="touchstart(index)" @touchend="touchend(index)" @click="thisToOtherPage(item)">
                <div class="listBox">
                    <div class="listTitle ellipsis">
                        {{item.custName}}
                        <div class="labelBox">
                            <span class="label ellipsis">
                                {{returnFactory(item)}}
                            </span>
                        </div>
                    </div>
                    <div class="listContent">
                        <div class="contentSpan">
                            <!-- <title-label :value="item.custState" :valueData="returnSysBoxValue('7', item.custState)"></title-label> -->
                            <span class="custType" style="">{{'编织方式：' + returnSysBoxValue('355', item.weavingMode)}}</span>&nbsp;
                            <span class="ownerCtId">{{returnRealName(item.ownerCtId)}}</span>
                        </div>
                        <template v-if="!isQueryRepeat()">
                            <div class="contentSpan contentSpan1 ellipsis">
                                <!-- <i class="iconfont icon-message"></i> -->
                                最终报价：{{item.finalQuotation || '-'}}
                            </div>
                            <div class="content-span">
                                <!-- <i class="iconfont icon-message"></i> -->
                                产品型号：{{item.productModel || '-'}}
                            </div>
                            <!-- <div class="contentSpan contentSpan1" v-else>
                                <i class="iconfont icon-message"></i>
                                {{$t('mx_mobile.Client.1585118248119')}}
                                暂无动态
                            </div> -->
                        </template>
                    </div>
                </div>
            </div>
            <otDistribution @setTitle="setTitle" @setMenu="setMenu" @getListData="getData" ref="otDistribution" :moduleCode="moduleCode" optCode="otDistribution" :item="item"></otDistribution>
            <otReceive @setTitle="setTitle" @setMenu="setMenu" @getListData="getData" ref="otReceive" :moduleCode="moduleCode" optCode="otReceive" :item="item"></otReceive>
        </template>
    </div>
</template>
<script>
import titleLabel from './titleLabel/index'
import otDistribution from '@/page/Client/CustPublic/optVue/otDistribution/index'
import otReceive from '@/page/Client/CustPublic/optVue/otReceive/index'
import { mapGetters } from 'vuex'

export default {
    name: 'custListView',
    props: {
        countNum: {
            type: Number,
            default: 0
        },
        countNumNo: {
            type: Boolean,
            default: false
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        personnelTable: {
            type: Array,
            default: function () {
                return []
            }
        },
        listLabels: {
            type: Array,
            default: function () {
                return []
            }
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            loading: false,
            moduleCode: 'CP010',
            item: {},
            isRoute: this.$router.currentRoute.path || '',
            refsData: ''
        }
    },
    created() {
    },
    mounted() {
    },
    computed: {
        ...mapGetters(['greyFunctions', 'currencyShow', 'sysBoxValueMap'])
    },
    methods: {
        // 点击了领取
        receive(item) {
            this.item = item
            this.refsData = 'otReceive'
            this.rightCheck(item)
        },
        // 点击了分配给
        AssignedTo(item) {
            this.item = item
            this.refsData = 'otDistribution'
            this.rightCheck(item)
        },
        setTitle() {
        },
        setMenu() {
            this.$emit('RefreshGetData')
        },
        // 权限校验
        rightCheck(item) {
            let _this = this
            let obj = {
                moduleCode: 'BF001',
                identFieldValue: item.custId,
                optCode: _this.refsData
            }

            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: _this.$t('mx_mobile.Client.1585281647545') // 加载中...
            })
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.document_rightCheck_do, { params: obj }).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    // 关闭客户查重的搜索框
                    _this.$emit('showScreen')

                    if (_this.refsData == 'otDistribution' || _this.refsData == 'otReceive') {
                        _this.$toast.clear()
                        _this.$refs[_this.refsData].openWindow()
                    }
                } else {
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        getData(type) {
            if (type == 'close') {
                this.$router.back()
                return false
            }
        },
        isQueryRepeat() {
            return this.$route.path.includes('/queryRepeat')
        },
        touchend(index) {
            let dom = document.getElementsByClassName('clientList')
            dom[index].style.backgroundColor = 'white'
        },
        touchstart(index) {
            let dom = document.getElementsByClassName('clientList')
            dom[index].style.backgroundColor = '#fcf2f3'
        },
        returnRealName(ctId) {
            let realName = ''
            this.personnelTable.forEach(element => {
                if (element.ctId == ctId) {
                    realName = element.realName
                }
            })
            return realName
        },
        returnAllSysBox(item) {
            let list = []
            let custLevel, custSource, tradeType
            custLevel = this.returnSysBoxValue('9', item.custLevel)
            custSource = this.returnSysBoxValue('8', item.custSource)
            tradeType = this.returnSysBoxValue('18', item.tradeType)
            if (custLevel != '') {
                list.push(custLevel)
            }
            if (custSource != '') {
                list.push(custSource)
            }
            if (tradeType != '') {
                list.push(tradeType)
            }
            return list.join('、')
        },
        returnSysBoxValue(dictCode, dictItemCode) {
            let itemName = ''
            if (!dictItemCode) {
                return ''
            }
            this.sysBoxValue.forEach(element => {
                if (element.dictCode == dictCode) {
                    element.dictItems.forEach(items => {
                        if (items.dictItemCode == dictItemCode) {
                            itemName = items.itemName
                        }
                    })
                }
            })
            return itemName
        },
        returnFactory(item) {
            let labelName = '-'
            if (item.factory) {
                labelName = this.sysBoxValueMap[352]?.dictItem[item.factory * 1].itemName
            }
            return labelName
        },
        toOtherPage(url) {
            this.$router.push({ path: url, query: this.g_setQuery() })
        },
        // 跳转页面详情
        thisToOtherPage(item) {
            localStorage.setItem('pageId', item.calculatePriceId)
            const url = '/PriceCalc/detail/' + item.calculatePriceId
            this.toOtherPage(url)
        }
    },
    watch: {
        $route(to, from) {
            if (to.path) {
                this.isRoute = to.path
            }
        }
    },
    components: {
        'title-label': titleLabel,
        'otDistribution': otDistribution,
        'otReceive': otReceive
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
