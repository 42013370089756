<template>
    <div class="searchLabels">
        <div class="rightBox">
            <!-- <span :class="[check === '' ? 'label2':'label1 text-hover']" @click="clearData()" class="noLabel">不限</span> -->
            <span v-for="(item, index) in options" :key="item.dictItemCode" class="label ellipsis" :class="[check == item.dictItemCode+'' ? 'label2':'label1']" :style="index!=0&&(index+1)%3 == 0?'margin-right:0':''" @click="changeItem(item.dictItemCode)">{{item.itemName}}</span>
            <!-- <component class="control" v-bind:is="'control'+list.controlTypeId"  :optCode="optCode"  :moduleCode="moduleCode"  ref="control" :itemData="list"></component> -->
            <!-- <template v-else>
                <component class="control" v-bind:is="'group'+list.fieldGroup"  :optCode="optCode"  :moduleCode="moduleCode"  ref="control"  size="mini" :itemData="list"></component>
            </template> -->
        </div>
    </div>
</template>

<script>
// import Controls from '@/page/Client/CustPublic/Controls/index.js'
// import GroupControls from '@/page/Client/CustPublic/GroupControls/index.js'
export default {
    name: 'searchLabels',
    props: {
        list: {
            type: Object,
            default: function () {
                return {}
            }
        },
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: 'otView'
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            options: [],
            check: '-1'
        }
    },
    created() {
    },
    mounted() {
        this.returnList()
    },
    methods: {
        submit() {
            let obj = {}
            if (this.check != -1) {
                obj[this.list.fieldName] = this.check
            }
            return obj
        },
        clear() {
            this.check = '-1'
        },
        changeItem(value) {
            let _this = this
            this.$emit('update:controlData', value)
            if (_this.check !== value) {
                _this.check = value
                let newValue = {
                    key: _this.list.fieldName,
                    value: value
                }
                _this.$emit('change', newValue, 'ver')
            }
        },
        returnList() {
            this.sysBoxValue.forEach(element => {
                if (element.dictCode == this.list.dictCode) {
                    let options = []
                    element.dictItems.forEach(item => {
                        if (item.inUse == '1') {
                            options.push(item)
                        }
                    })
                    options.unshift({
                        dictItemCode: '-1',
                        itemName: this.$t('mx_mobile.Monitor.1585208312818')// 不限
                    })
                    this.options = options
                }
            })
        }
    },
    computed: {
    },
    watch: {
        sysBoxValue: function (val, old) {
            this.returnList()
        }
    },
    components: {}
    // components: Object.assign({

    // }, Controls, GroupControls)
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.searchLabels {
    .rightBox {
        min-height: 36px;
        .label,
        .noLabel {
            display: inline-block;
            .width(65px);
            .height(26px);
            text-align: center;
            .line-height(26px);
            .border-radius(4px);
            .font-size(12px);
            .margin-bottom(17px);
            .margin-right(19px);
        }
        .label1 {
            color: #606266;
            background: #efefef;
        }
        .label2 {
            color: white;
            background: #d0021b;
        }
        .control {
            margin-bottom: 0;
            line-height: 33px;
            display: inline-block;
        }
    }
}
</style>
